import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import createConnector from '../core/createConnector';
import { getCurrentRefinementValue, refineValue, getResults } from '../core/indexUtils';

function getId() {
  return 'page';
}

function getCurrentRefinement(props, searchState, context) {
  var id = getId();
  var page = 1;
  return getCurrentRefinementValue(props, searchState, context, id, page, function (currentRefinement) {
    if (typeof currentRefinement === 'string') {
      currentRefinement = parseInt(currentRefinement, 10);
    }

    return currentRefinement;
  });
}
/**
 * InfiniteHits connector provides the logic to create connected
 * components that will render an continuous list of results retrieved from
 * Algolia. This connector provides a function to load more results.
 * @name connectInfiniteHits
 * @kind connector
 * @providedPropType {array.<object>} hits - the records that matched the search state
 * @providedPropType {boolean} hasMore - indicates if there are more pages to load
 * @providedPropType {function} refine - call to load more results
 */


export default createConnector({
  displayName: 'AlgoliaInfiniteHits',
  getProvidedProps: function getProvidedProps(props, searchState, searchResults) {
    var results = getResults(searchResults, this.context);
    this._allResults = this._allResults || [];
    this._previousPage = this._previousPage || 0;

    if (!results) {
      return {
        hits: [],
        hasMore: false
      };
    }

    var hits = results.hits,
        page = results.page,
        nbPages = results.nbPages;

    if (page === 0) {
      this._allResults = hits;
    } else if (page > this._previousPage) {
      this._allResults = [].concat(_toConsumableArray(this._allResults), _toConsumableArray(hits));
    } else if (page < this._previousPage) {
      this._allResults = hits;
    }

    var lastPageIndex = nbPages - 1;
    var hasMore = page < lastPageIndex;
    this._previousPage = page;
    return {
      hits: this._allResults,
      hasMore: hasMore
    };
  },
  getSearchParameters: function getSearchParameters(searchParameters, props, searchState) {
    return searchParameters.setQueryParameters({
      page: getCurrentRefinement(props, searchState, this.context) - 1
    });
  },
  refine: function refine(props, searchState) {
    var id = getId();
    var nextPage = getCurrentRefinement(props, searchState, this.context) + 1;

    var nextValue = _defineProperty({}, id, nextPage);

    var resetPage = false;
    return refineValue(searchState, nextValue, this.context, resetPage);
  }
});